<template>
  <transition name="fade">
    <div v-if="!loading" class="linktree">
      <div class="container">
        <header>
          <img 
            v-if="data.header.headerImage" 
            class="headerImage shadow" 
            :src="data.header.headerImage.sizes.large"
          >
          <h1 v-if="data.header.title">{{data.header.title}}</h1>
          <p v-if="data.header.description">
            <strong>{{data.header.description}}</strong>
          </p>
        </header>
        <section v-if="data.buttons">
          <Button
            v-for="(button,index) in data.buttons"
            v-bind:key="index"
            v-bind:button="button"
            ripple="ripple"
          />
        </section>
        <footer>
          <img class="logo" src="@/assets/logo.png">
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, computed } from "vue";
// @ is an alias to /src
import Button from '@/components/linktree/Button.vue'

export default {
  name: 'Home',
  components: {
    Button
  },
  data(){
    return {
      loading: true,
      data: [],
    }
  },
  methods: {
    getData() {
      this.axios.get('https://admin.simtschuk.de/wp-json/wp/v2/pages/10?_fields=acf&acf_format=standard').then((response) => {
        //console.log(response.data)
        this.data = response.data.acf
      }).then( test => {
        //console.log(this.data)
        this.loading = false;
      });
    }
  },
  created(){
    this.getData();
  },

}
</script>

<style lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.linktree {
  padding: 20px;
  min-height: 100vh;
  height: 100%;
  background: rgb(148,1,108);
  background: -moz-linear-gradient(211deg, rgba(148,1,108,1) 3%, rgba(188,1,118,1) 26%, rgba(210,0,128,1) 52%, rgba(84,1,141,1) 83%, rgba(0,5,126,1) 100%);
  background: -webkit-linear-gradient(211deg, rgba(148,1,108,1) 3%, rgba(188,1,118,1) 26%, rgba(210,0,128,1) 52%, rgba(84,1,141,1) 83%, rgba(0,5,126,1) 100%);
  background: linear-gradient(211deg, rgba(148,1,108,1) 3%, rgba(188,1,118,1) 26%, rgba(210,0,128,1) 52%, rgba(84,1,141,1) 83%, rgba(0,5,126,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#94016c",endColorstr="#00057e",GradientType=1);
  .container {
    max-width: 500px;
    margin: 0 auto;
  }

  header {

    * {
      color: #fff;
    }

    margin-bottom: 30px;

    .headerImage {
      height: 120px;
      width: 120px;
      border-radius: 50%;
    }

    h1 {
      font-size: 1.3em;
    }

  }

  .logo {
    max-width: 150px;
    width: 100%;
    margin-top: 15px;
  }



}


</style>