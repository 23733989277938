import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VWave from 'v-wave'
import axios from 'axios'
import VueAxios from 'vue-axios'

import '@/assets/css/all.css';

const app = createApp(App)
app.use(router)
app.use(VWave)
app.use(VueAxios,axios)
app.provide('axios', app.config.globalProperties.axios)  // provide 'axios'
app.mount('#app')
