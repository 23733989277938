<template>
  <a
    class="shadow"
    v-wave
    :href="button.link"
    target="_blank"
  >
    <span class="test">
      <span 
        v-if="button.icon"
        v-html="button.icon"
      />
      {{button.text}}
    </span>
  </a>
</template>

<script>
export default {
  name: 'Linktree Buttons',
  props: {
    button: Object
  },
  mounted() {
    //console.log(this.button)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-bottom: 15px;
  cursor: pointer;
  height: 50px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
}

</style>
